import React from 'react';

interface Experience {
  company: string;
  title: string;
  location: string;
  date: string;
  points: string[];
}

interface Education {
  school: string;
  degree: string;
  date: string;
  details?: string[];
}

function ResumePage() {
  const experiences: Experience[] = [
    {
      company: "Amazon Web Services (AWS)",
      title: "Software Development Engineer II",
      location: "Santa Clara, California",
      date: "April 2024 - Present",
      points: [
        "Implementing the backend and designing the architecture for a novel networking service.",
        "Working with Java, Kotlin, Python, and Typescript CDK to define infrastructure components (databases, messaging, etc)."
      ]
    },
    {
      company: "Amazon Web Services (AWS)",
      title: "Software Development Engineer",
      location: "Santa Clara, California",
      date: "Oct 2022 - April 2024",
      points: [
        "Designing and building scalable, performant, distributed backend systems used by AWS networking to store, process, and distribute data and metadata.",
        "Significantly improving the performance (70% latency reduction) and reducing the cost (60% monthly cost reduction) of a core networking service.",
        "Designing, building, and managing the rollout of a new service, supporting hundreds of thousands of transactions per second.",
        "Mentoring engineers and interns, leading and supervising intern project to implement widely requested features within our core systems.",
        "Using technologies including Java, Kotlin, Python, React, TypeScript, and AWS cloud offerings (DDB, SQS, S3, etc)."
      ]
    },
    {
        company: "Amazon Web Services (AWS)",
        title: "Security Engineer",
        location: "Remote",
        date: "April 2022 - Oct 2022",
        points: [
          "Building large scale information security API services / microservices. Using Java, Python, TypeScript, and AWS CDK.",
        ]
    },
    {
        company: "Kasada",
        title: "Security Engineer",
        location: "Walnut Creek, California",
        date: "Oct 2021 - April 2022",
        points: [
          "Software engineering, primarily with Python, JavaScript, and TypeScript.",
          "Implementing proactive detection and profiling modules to mitigate threats from bots and malicious automation.",
          "Cloud architecture using the AWS CDK.",
        ]
    },
    {
        company: "Hewlett Packard Enterprise",
        title: "Cyber Security Engineer",
        location: "Roseville, California",
        date: "Jun 2020 - Sep 2021",
        points: [
          "Deploying SOAR solution and writing software to automate incident response.",
          "Using Python and Go to write tooling and automation software.",
          "Performing malware analysis, network traffic analysis, and endpoint analysis."
        ]
      },
      {
        company: "Hewlett Packard Enterprise",
        title: "Cyber Security Intern",
        location: "Roseville, California",
        date: "Jun 2019 - Aug 2019",
        points: [
          "Full-stack development of a new incident response ticketing system - using Python, PHP, JavaScript, and MySQL."
        ]
      },
      {
        company: "UC Davis",
        title: "Cybersecurity Teaching Assistant",
        location: "Sacramento, California",
        date: "Jan 2020 - Jul 2021",
        points: [
          "Assisting professors and students at a cybersecurity bootcamp.",
          "Teaching students concepts including Kali Linux, Metasploit, wireshark, network security, digital forensics, Splunk, Python, malware analysis, vulnerability scanning, application security, reverse engineering, and more."
        ]
      }
    // ... Add other experiences similarly
  ];

  interface Activity {
    title: string;
    date: string;
    points: string[];
  }

  const activities: Activity[] = [
    {
      title: "Home Lab Setup and Maintenance",
      date: "May 2019 - Present",
      points: [
        "Built, set up, and secured a Proxmox virtualization server to fulfill various needs, including GitLab, Jenkins, Maven, Ansible, Apache web server, various game servers, storage and backups, etc."
      ]
    },
    {
      title: "Backend Web Developer for Redact.dev",
      date: "Dec 2020 - Jan 2021",
      points: [
        "Web development work with Typescript / NodeJS, produced a wrapper over interactions with social media sites."
      ]
    }
  ];

  const education: Education[] = [
    {
      school: "California State University, Sacramento",
      degree: "Bachelor of Science in Computer Science",
      date: "Jan 2018 - May 2020",
      details: ["Certificate in Information Assurance and Security"]
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-2 sm:px-4 py-4 sm:py-8">
      <div className="glass-card rounded-xl p-3 sm:p-8 aero-glow">
        <header className="text-center mb-8 sm:mb-12">
          <h1 className="text-4xl font-light text-white mb-4">Daniel Kuzmin</h1>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4 text-aero-white/80 font-light">
            <span>Santa Clara, CA</span>
            <span className="hidden sm:inline">•</span>
            <span className="break-all">danielkuzmin@gmail.com</span>
            <span className="hidden sm:inline">•</span>
            <a 
              href="https://linkedin.com/in/danielkuzmin" 
              className="text-aero-blue hover:text-white transition-colors break-all"
              target="_blank"
              rel="noopener noreferrer"
            >
              linkedin.com/in/danielkuzmin
            </a>
          </div>
        </header>

        <section className="mb-8 sm:mb-12">
          <h2 className="text-xl sm:text-2xl font-light text-white mb-4 sm:mb-6 border-b border-aero-border pb-2">
            Experience
          </h2>
          <div className="space-y-6 sm:space-y-8">
            {experiences.map((exp, index) => (
              <div key={index} className="bg-aero-glass backdrop-blur-sm border border-aero-border rounded-lg p-3 sm:p-6 hover:bg-black/40 transition-colors">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-2">
                  <div>
                    <h3 className="text-lg sm:text-xl font-light text-white">{exp.company}</h3>
                    <p className="text-aero-white/90">{exp.title}</p>
                    <p className="text-aero-white/70 text-sm">{exp.location}</p>
                  </div>
                  <span className="text-aero-white/70 text-sm mt-2 sm:mt-0">{exp.date}</span>
                </div>
                <ul className="list-disc list-inside text-aero-white/90 space-y-2 mt-4">
                  {exp.points.map((point, i) => (
                    <li key={i} className="text-sm leading-relaxed pl-4 -indent-4 break-words">{point}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-8 sm:mb-12">
          <h2 className="text-xl sm:text-2xl font-light text-white mb-4 sm:mb-6 border-b border-aero-border pb-2">
            Education
          </h2>
          <div className="space-y-6 sm:space-y-8">
            {education.map((edu, index) => (
              <div key={index} className="bg-aero-glass backdrop-blur-sm border border-aero-border rounded-lg p-4 sm:p-6 hover:bg-black/40 transition-colors">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-2">
                  <div>
                    <h3 className="text-lg sm:text-xl font-light text-white">{edu.school}</h3>
                    <p className="text-aero-white/90">{edu.degree}</p>
                  </div>
                  <span className="text-aero-white/70 text-sm mt-2 sm:mt-0">{edu.date}</span>
                </div>
                {edu.details && (
                  <ul className="list-disc list-inside text-aero-white/90 space-y-2 mt-4">
                    {edu.details.map((detail, i) => (
                      <li key={i} className="text-sm">{detail}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </section>

        <section className="mb-8 sm:mb-12">
          <h2 className="text-xl sm:text-2xl font-light text-white mb-4 sm:mb-6 border-b border-aero-border pb-2">
            Additional Activities
          </h2>
          <div className="space-y-6 sm:space-y-8">
            {activities.map((activity, index) => (
              <div key={index} className="bg-aero-glass backdrop-blur-sm border border-aero-border rounded-lg p-4 sm:p-6 hover:bg-black/40 transition-colors">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-2">
                  <h3 className="text-lg sm:text-xl font-light text-white">{activity.title}</h3>
                  <span className="text-aero-white/70 text-sm mt-2 sm:mt-0">{activity.date}</span>
                </div>
                <ul className="list-disc list-inside text-aero-white/90 space-y-2 mt-4">
                  {activity.points.map((point, i) => (
                    <li key={i} className="text-sm leading-relaxed pl-4 -indent-4 break-words">{point}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default ResumePage;
