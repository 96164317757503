// src/utils.ts
import { BlogPost } from './types';
import { BLOG_POSTS } from './generated/posts';

export function getAllPosts(): BlogPost[] {
  return BLOG_POSTS.sort((a, b) => {
    const dateA = new Date(a.frontmatter.date + 'T00:00:00Z');
    const dateB = new Date(b.frontmatter.date + 'T00:00:00Z');
    return dateB.getTime() - dateA.getTime();
  });
}

export function getPostBySlug(slug: string): BlogPost | undefined {
  return BLOG_POSTS.find(post => post.slug === slug);
}
