import React from 'react';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <div className="max-w-4xl mx-auto px-4 text-center">
      <div className="glass-card rounded-xl p-6 sm:p-12 transform hover:scale-[1.01] transition-all duration-300 aero-glow">
        <h1 className="text-4xl sm:text-6xl font-light text-white mb-6 sm:mb-8">
          Daniel Kuzmin
        </h1>
        <p className="text-lg sm:text-2xl text-aero-white/80 font-light leading-relaxed mb-8 sm:mb-12 max-w-2xl mx-auto">
          Software engineer in the Bay Area, currently at AWS.
          Building distributed systems to store, process, and vend data across the AWS network.
          Experienced in Java, Python, Kotlin, TypeScript, and cloud infrastructure.
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
          <Link 
            to="/resume" 
            className="glass-button px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg rounded-full inline-flex items-center justify-center space-x-2 shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <span>View Resume</span>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </Link>
          <Link 
            to="/blog" 
            className="glass-button px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg rounded-full inline-flex items-center justify-center space-x-2 shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <span>View Blog</span>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePage;