import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';

function Layout() {
  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-backdrop to-aero-dark text-white flex flex-col">
      <div className="fixed inset-0 bg-gradient-radial from-aero-blue/10 via-transparent to-transparent opacity-30" />
      <div className="relative flex flex-col flex-grow w-full">
        <Navbar />
        <main className="flex-grow w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <Outlet />
        </main>
        <footer className="glass-card py-12 w-full">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <p className="text-aero-white/80 font-light">&copy; 2024 Daniel Kuzmin</p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Layout;
