
// Generated file - do not edit directly
import { BlogPost } from '../types';

export const BLOG_POSTS: BlogPost[] = [
  {
    "slug": "first-post",
    "frontmatter": {
      "title": "Intro",
      "date": "2024-12-07T00:00:00.000Z",
      "excerpt": "An introduction to my blogging journey"
    },
    "content": "# Welcome to My First Post\n\nHello, world! This is my first blog post using a custom markdown setup.\n"
  },
  {
    "slug": "second-post",
    "frontmatter": {
      "title": "Intro2",
      "date": "2024-12-08T00:00:00.000Z",
      "excerpt": "An second introduction to my blogging journey"
    },
    "content": "# Welcome to My Second Post\n\nHello, world! This is my second blog post using a custom markdown setup.\n"
  }
];
