import React from 'react';
import { Link } from 'react-router-dom';
import { getAllPosts } from '../utils';

const formatDate = (dateString: string) => {
  try {
    // Split the date string into parts
    const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
    // Create date using UTC to avoid timezone issues
    const date = new Date(Date.UTC(year, month - 1, day));
    
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (e) {
    console.error('Error parsing date:', dateString);
    return 'Invalid date';
  }
};

function BlogListPage() {
  const posts = getAllPosts().sort((a, b) => {
    const dateA = new Date(a.frontmatter.date);
    const dateB = new Date(b.frontmatter.date);
    return dateB.getTime() - dateA.getTime();
  });

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-light text-white mb-8">Blog Posts</h1>
      <div className="space-y-6">
        {posts.map((post) => (
          <div 
            key={post.slug} 
            className="glass-card rounded-xl overflow-hidden hover:scale-[1.01] transition-all duration-300"
          >
            <div className="p-6">
              <h2 className="text-2xl font-light text-white mb-2">
                <Link 
                  to={`/blog/${post.slug}`} 
                  className="hover:text-aero-blue transition-colors"
                >
                  {post.frontmatter.title}
                </Link>
              </h2>
              <p className="text-aero-white/70 mb-4">{post.frontmatter.excerpt}</p>
              <div className="flex justify-between items-center">
                <span className="text-sm text-aero-white/60">
                  {formatDate(post.frontmatter.date)}
                </span>
                <Link 
                  to={`/blog/${post.slug}`} 
                  className="glass-button px-4 py-2 rounded-full text-white text-sm"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlogListPage;