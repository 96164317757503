import React from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getPostBySlug } from '../utils';

function BlogPostPage() {
  const { slug } = useParams<{ slug: string }>();
  const post = slug ? getPostBySlug(slug) : null;

  if (!post) return <div className="container mx-auto px-4 text-2xl text-aero-white/70">Post not found</div>;

  return (
    <div className="max-w-4xl mx-auto px-2 sm:px-4 py-4 sm:py-8">
      <article className="glass-card rounded-xl p-4 sm:p-8">
        <h1 className="text-4xl font-light text-white mb-4">{post.frontmatter.title}</h1>
        <div className="text-aero-white/60 mb-8">
          <p>Published on {new Date(post.frontmatter.date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}</p>
        </div>
        <ReactMarkdown 
          remarkPlugins={[remarkGfm]}
          components={{
            h2: ({node, ...props}) => (
              <h2 className="text-3xl font-light text-white mt-8 mb-4 border-b border-aero-border pb-2" {...props} />
            ),
            p: ({node, ...props}) => (
              <p className="text-aero-white/80 mb-4 leading-relaxed" {...props} />
            ),
            code: ({node, className, children, ...props}) => (
              <code 
                className={`bg-black/30 text-emerald-400 rounded px-2 py-1 font-mono text-sm ${className}`} 
                {...props}
              >
                {children}
              </code>
            ),
            pre: ({node, ...props}) => (
              <pre 
                className="bg-black/40 border border-aero-border rounded-lg p-4 overflow-x-auto my-4 text-emerald-300" 
                {...props} 
              />
            ),
            a: ({node, ...props}) => (
              <a 
                className="text-aero-blue hover:text-white underline transition-colors duration-300" 
                {...props} 
              />
            ),
            ul: ({node, ...props}) => (
              <ul className="list-disc list-inside text-aero-white/80 mb-4" {...props} />
            ),
            li: ({node, ...props}) => (
              <li className="mb-2" {...props} />
            )
          }}
        >
          {post.content}
        </ReactMarkdown>
      </article>
    </div>
  );
}

export default BlogPostPage;